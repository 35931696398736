import { SwiperComponent } from './swiper';
import PhotoSwipe from 'photoswipe';
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import './../scss/_variables';
import 'swiper/scss';
import 'swiper/scss/navigation';
import 'swiper/scss/pagination';
import 'swiper/scss/effect-fade';
import 'photoswipe/style.css';
import './../scss/main';
class Application {
    constructor() {
    }
    run() {
        this.initSlider();
        const lightbox = new PhotoSwipeLightbox({
            gallery: '#gallery--no-dynamic-import',
            children: 'a',
            counter: false,
            pswpModule: PhotoSwipe
        });
        lightbox.init();
    }
    initSlider() {
        const sliders = document.querySelectorAll('.j-slider-component');
        sliders.forEach(slider => {
            new SwiperComponent({
                element: slider,
                options: {}
            }).run();
        });
    }
}
document.addEventListener("DOMContentLoaded", function (event) {
    const application = new Application();
    application.run();
});
